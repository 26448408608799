.App {
  text-align: center;
  color: Green;
}
body,
html {
  height: auto !important;
}
body {
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
* {
  /* font-family: "ArbFONTS-Hacen-Liner-XL.ttf" !important; */
  letter-spacing: 0.08rem;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.phoneNb {
  direction: ltr;
}

@media only screen and (max-width: 850px) {
  input::placeholder {
    font-size: 10px;
  }
}
html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
img {
  object-fit: cover;
}
