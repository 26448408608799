/* === BASE HEADING === */

.titleH1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: rgb(137, 165, 142);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.titleH1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
.titleH1 em {
  font-style: normal;
  font-weight: 600;
}

/* === HEADING STYLE #1 === */
.one .titleH1 {
  text-align: center;
  padding-bottom: 5px;
}
.one .titleH1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -14px;
  background-color: rgb(137, 165, 142);
}
/* .one .titleH1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: rgb(137, 165, 142);
} */
