@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product_Sans_Regular";
  src: local("Product_Sans_Regular"),
    url("./Amiri-Regular.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}

/* body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #fcf3f6;
} */
/* body {
  font-feature-settings: "tnum";
  background-color: #fff;
  color: rgba(0, 0, 0, .85);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
} */

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

a {
  color: inherit !important;
  text-decoration: inherit !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

h3 {
  font-size: inherit !important;
}

.table > :not(:first-child) {
  border-top: 0px solid currentColor !important;
}

ol,
ul {
  padding-left: 0rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  margin-bottom: 0em !important;
}

.container_custom {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 1280px) {
  .container_custom {
    width: 75%;
  }
}

@media (min-width: 1024px) {
  .container_custom {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.p-15 {
  padding: 1.25rem !important;
}
.uppercase {
  text-transform: uppercase !important;
}

.semibold {
  font-weight: 600 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.slick-slider {
  /* spacing in between each slide */
  margin-top: 20px;
}
.hl {
  background: #ffc600;
}
.bg {
  /* The image used */
  background-image: url("./blured_background.PNG");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_color {
  background-color: #89a58e;
}

.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav {
  text-align: center;
}

.nav ul {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

.nav ul li {
  position: relative;
  float: left;
}

.nav ul li + li {
  margin-left: 1px;
}

.nav ul li a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 11px 10px;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.nav ul li > ul {
  display: none;
  position: absolute;
  width: 150px;
  top: 100%;
  left: -1px;
  z-index: 99;
  text-align: left;
  background-color: #89a58e;
  border-radius: 5px;
  color: white;
}

.nav ul li > ul li {
  float: none;
  margin: 0;
}

.nav ul li > ul li a {
  display: block;
  border-top: 1px solid #555;
}

.nav ul li > ul li a:hover {
  border-top: 1px solid #646464;
  z-index: 9;
  background-color: #527558;
}

.nav ul li.active {
  pointer-events: none;
}
