.ul {
  font-size: 12px;
  background-color: white;
  margin-bottom: 0px !important;
  color: rgb(137, 165, 142);
  font-weight: 700;
}

.li_ltr {
  list-style: none;
  display: inline-block;
  border-left: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
}

.li_ltr:first-child {
  border: none;
}

.li_ltr:last-child {
  transform: skew(-25deg);
  background-color: rgb(137, 165, 142);
  right: 20px;
  position: relative;
  width: 50%;
}

.li_ltr:last-child span {
  display: inline-block; /* or "block" */
  transform: skew(25deg);
}
.span2_ltr {
  position: absolute;
  right: 200px;
}
@media only screen and (max-width: 850px) {
  .span2_ltr {
    right: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .span2_ltr {
    right: 0px;
  }
}
.li_rtl {
  list-style: none;
  display: inline-block;
  border-left: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
}

.li_rtl:first-child {
  border: none;
}

.li_rtl:last-child {
  transform: skew(-25deg);
  background-color: rgb(137, 165, 142);
  left: 20px;
  position: relative;
  width: 50%;
}

.li_rtl:last-child span {
  display: inline-block; /* or "block" */
  transform: skew(25deg);
}
.span2_rtl {
  position: absolute;
  left: 200px;
}
@media only screen and (max-width: 850px) {
  .span2_rtl {
    left: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .span2_rtl {
    left: 0px;
  }
  .ul {
    font-size: 10px;
    font-weight: 700;
  }

  .li_rtl {
    font-size: 10px;
  }
  .li_ltr {
    font-size: 10px;
  }
}
