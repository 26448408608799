.left_ul,
.right_ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
}

.header li {
    list-style: none;
}

.header a {
    text-decoration: none;
    color: black;
}

.header a:hover,
.header a:active,
.header a.active {
    color: #f27a1b !important;
}

.dropdown_content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown:hover .dropdown_content {
    display: block;
}


.suggestions {
    position: absolute;
    z-index: 99;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
  }
  .suggestions .li {
    background: white;
    list-style: none;
    border-bottom: 1px solid #D8D8D8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    margin: 0;
    padding: 5px;
    transition: background 0.2s;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }    
  .suggestions .li:hover {
    background: lightgray;
    cursor: pointer;
 
  }    
